import { useEffect } from "react";
import amplitude from 'amplitude-js';
import ContinueButton from "../../UI/continueButton/ContinueButton";
import ResultHeader from "../../UI/resultHeader/ResultHeader";
import styles from "./LifestyleResult.module.scss";
import strings from "../../../assets/localization/strings.jsx";
import LazyImage from "../../UI/lazyImage/LazyImage";
import VideoFeedbacks from "../../UI/videoFeedbacks/VideoFeedbacks";

const LifestyleResult = props => {
    const { nextStep, data } = props;
    const themeData = data.themeData
    const pageInfo = data.settings.lifestyleResult
    const textInfo = strings.lifestyle.result
    const gender = localStorage.getItem("gender");
    const mainContainerClass = `${styles.mainContainer} ${styles[`${themeData.pageContainer}Container`]} flex-column`

    useEffect(() => {
         dataLayer.push({
                                "event": "ga4event",
                                "event_category": "screen_onboarding_section_lifestyle_appeared",
                                "funnel_type": data.campaign,
                                "step": pageInfo.order
                                                          });
        amplitude.getInstance().logEvent('screen_onboarding_section_lifestyle_appeared');
    }, [])

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_section_lifestyle_tapped');
        nextStep();
    }

    return (
        <div className={mainContainerClass}>
            <ResultHeader
                url={'/images/result/lifestyle-result.jpg'}
                urlWebp={'/images/result/lifestyle-result.webp'}
                theme={themeData?.menuProgressBackground}
            />
            <h1 className={styles.commentTitle}>{textInfo.title}</h1>
            <div className={`${styles.comment} flex-row`}>
                <div className={styles.commentImageBox}>
                    { pageInfo.video  && strings.getLanguage() === 'en' && (gender !== 'Male')
                        ? <VideoFeedbacks
                            feedbacks={pageInfo.feedbacks}
                            title={false}
                            name={false}
                            view="rectangle"
                            themeData={themeData}
                        />
                        : <LazyImage
                            className={styles.commentImage}
                            srcWebp={gender === 'Male' ? pageInfo.resultImageMWebp : pageInfo.resultImageWebp}
                            src={gender === 'Male' ? pageInfo.resultImageM : pageInfo.resultImage}
                            alt=""
                        />}
                </div>
                <div className={`${styles.commentArea} flex-column`}>
                    {themeData.pageContainer === "lightPurple" ?
                        (<img loading="lazy" src="/images/stars-green.svg" alt="" />) :
                        (<img loading="lazy" src="/images/stars.svg" alt="" />)
                    }
                    <div className={styles.commentBox}>
                        { pageInfo.cfComment ? (
                            <>
                                <p className={styles.commentText}>
                                    { textInfo.cfCommentFirst }
                                </p>
                                <p className={styles.commentText}>
                                    { textInfo.cfCommentLast }
                                </p>
                            </>
                        ) : (
                            <p className={styles.commentText}>
                                { textInfo.comment }
                            </p>
                        )
                        }
                    </div>
                    <p className={styles.commentAuthor}>
                        {gender === 'Male' || pageInfo.authorGender === 'm' ? textInfo.commentAuthorM : textInfo.commentAuthor}
                    </p>
                </div>
            </div>
            <span className={styles.commentHint}>
                            {textInfo.commentHint.start}
                <span className={styles.hintBold}>{textInfo.commentHint.middle}</span>
                {textInfo.commentHint.end}
                        </span>
            <ContinueButton
                text={strings.continue}
                nextStep={clickContinue}
                theme={themeData.continueButton}
            />
        </div>
    )
}   

export default LifestyleResult;