
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router";
import ReactPixel from 'react-facebook-pixel';
import TiktokPixel from 'tiktok-pixel';
import sha256 from 'crypto-js/sha256';
import CheckoutForm from './CheckoutForm';
import amplitude from 'amplitude-js';
import moment from 'moment/moment';
import styles from "./PaymentProove.module.scss";
import LabelWithCheckbox from "../../components/UI/labelWithCheckbox/LabelWithCheckbox";

import React from 'react';
import strings from "../../assets/localization/strings.jsx";
import LazyImage from "../../components/UI/lazyImage/LazyImage";

const PaymentProove = (props) => {
    const { data } = props;
    const themeData = data;

    const mainContainerClass = `${styles.mainContainer} ${styles[`${themeData.pageContainer}Container`]}`

    return (
        <>
            <div className={mainContainerClass}>

            <div className={styles.results}>
             <LazyImage
                                                 srcWebp="/images/payment/results.webp"
                                                 src="/images/result/results.png"
                                                 alt=""
                                             />

{/*             <div className={styles.header}>{strings.payment.proove.title}</div> */}
{/*             <div className={styles.resultsGrid}> */}

{/*              <div className={`${styles.imagesContainerLeft} flex-between`}> */}
{/*                              <div className={styles.imageContainer}> */}
{/*                                  <LazyImage */}
{/*                                      srcWebp="/images/payment/result_before_1.webp" */}
{/*                                      src="/images/result/result_before_1.png" */}
{/*                                      alt="" */}
{/*                                  /> */}
{/*                                  <p className={styles.titleB}>{strings.payment.proove.results.before}</p> */}
{/*                              </div> */}
{/*                              <div className={styles.imageContainer}> */}
{/*                                  <LazyImage */}
{/*                                      srcWebp="/images/payment/result_after_1.webp" */}
{/*                                      src="/images/result/result_after_1.png" */}
{/*                                      alt="" */}
{/*                                  /> */}
{/*                                  <p className={styles.titleA}>{strings.payment.proove.results.after}</p> */}
{/*                              </div> */}
{/*             </div> */}

{/*               <div className={`${styles.imagesContainerRight} flex-between`}> */}
{/*                                          <div className={styles.imageContainer}> */}
{/*                                              <LazyImage */}
{/*                                                  srcWebp="/images/payment/result_before_2.webp" */}
{/*                                                  src="/images/result/result_before_2.png" */}
{/*                                                  alt="" */}
{/*                                              /> */}
{/*                                              <p className={styles.titleB}>{strings.payment.proove.results.before}</p> */}
{/*                                          </div> */}
{/*                                          <div className={styles.imageContainer}> */}
{/*                                              <LazyImage */}
{/*                                                  srcWebp="/images/payment/result_after_2.webp" */}
{/*                                                  src="/images/result/result_after_2.png" */}
{/*                                                  alt="" */}
{/*                                              /> */}
{/*                                              <p className={styles.titleA}>{strings.payment.proove.results.after}</p> */}
{/*                                          </div> */}
{/*                 </div> */}
{/*                    </div> */}
            </div>
            <div className={styles.guaranteed} >
            <LabelWithCheckbox
              key={0}
              size={"l"}
              theme={themeData.checkboxCardColor}
              labelText={strings.payment.proove.guaranteedHealth}
            />
            <LabelWithCheckbox
                          key={1}
                          size={"l"}
                          theme={themeData.checkboxCardColor}
                          labelText={strings.payment.proove.guaranteedWeightLoss}
            />
            <LabelWithCheckbox
                                      key={2}
                                      size={"l"}
                                      theme={themeData.checkboxCardColor}
                                      labelText={strings.payment.proove.guaranteedEnergy}
            />
            <LabelWithCheckbox
                                                  key={3}
                                                  size={"l"}
                                                  theme={themeData.checkboxCardColor}
                                                  labelText={strings.payment.proove.guaranteedResults}
            />
            </div>


            <div className={styles.anyDevice}>
             <div className={styles.anyDeviceHeader}>{strings.payment.proove.anyDevice}</div>
             <div className={styles.anyDeviceImage}>
                                                          <LazyImage
                                                              srcWebp="/images/payment/any_device.webp"
                                                              src="/images/result/any_device.png"
                                                              alt=""
                                                          />

                                                      </div>
            </div>

            <div className={styles.bonuses}>
            <div className={styles.bonusesHeader}>{strings.payment.proove.bonuses.header}</div>
             <div className={styles.bonusString}>
                        <span className={styles.bonus}>{strings.payment.proove.bonuses.mealPlan}</span>
                        <span className={styles.value}> ($99 {strings.payment.proove.bonuses.value})</span>
             </div>
             <div className={styles.bonusString}>
                       <span className={styles.bonus}>{strings.payment.proove.bonuses.feedback}</span>
                       <span className={styles.value}> ($99 {strings.payment.proove.bonuses.value})</span>
             </div>
             <div className={styles.bonusString}>
                      <span className={styles.bonus}>{strings.payment.proove.bonuses.workouts}</span>
                      <span className={styles.value}> ($199 {strings.payment.proove.bonuses.value})</span>
             </div>
             <div className={styles.bonusString}>
                      <span className={styles.bonus}>{strings.payment.proove.bonuses.tracker}</span>
                      <span className={styles.value}> ($99 {strings.payment.proove.bonuses.value})</span>
             </div>
             <div className={styles.bonusString}>
                      <span className={styles.bonus}>{strings.payment.proove.bonuses.vip}</span>
                      <span className={styles.value}> ($199 {strings.payment.proove.bonuses.value})</span>
             </div>
             <div className={styles.total}>
                      <span className={styles.text}>{strings.payment.proove.bonuses.total.thats}</span>
                      <span className={styles.redText}>$695 </span>
                      <span className={styles.text}>{strings.payment.proove.bonuses.total.worth}</span>
                      <span className={styles.redText}>{strings.payment.proove.bonuses.total.free}</span>
                      <span className={styles.text}>{strings.payment.proove.bonuses.total.join}</span>
              </div>
            </div>
         </div>
        </>
    );
}

export default PaymentProove;