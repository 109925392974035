import { useEffect } from "react";
import amplitude from 'amplitude-js';
import ContinueButton from "../../UI/continueButton/ContinueButton";
import strings from "../../../assets/localization/strings.jsx";
import styles from "./Benefits.module.scss";
import LazyImage from "../../UI/lazyImage/LazyImage";
import LabelWithCheckbox from "../../UI/labelWithCheckbox/LabelWithCheckbox";

const Benefits = props => {
    const { nextStep, data } = props;
    const themeData = data.themeData
    const pageInfo = data.settings.benefits;
    const textInfo = strings.plan.benefits;
    const gender = localStorage.getItem("gender");
    const mainContainerClass = `${styles.mainContainer} ${styles[`${themeData.pageContainer}Container`]} flex-column`

    useEffect(() => {
        dataLayer.push({
                                        "event": "ga4event",
                                        "event_category": "screen_onboarding_benefits_appeared",
                                        "funnel_type": data.campaign,
                                        "step": pageInfo.order
                                                                  });
        amplitude.getInstance().logEvent('screen_onboarding_benefits_appeared');
    }, [])

    const clickContinue = () => {
        amplitude.getInstance().logEvent('button_onboarding_continue_tapped');
        nextStep();
    }
    let configImage = gender === "Male" ?  pageInfo.imageM :  pageInfo.image;
    let configImageWebp = gender === "Male" ?  pageInfo.imageMWebp :  pageInfo.imageWebp;

    let image = configImage !== null && configImage !== undefined
                ? configImage : "/images/plan/benefits-image.png";
    let imageWebp = configImageWebp !== null && configImageWebp !== undefined
                    ? configImageWebp : "/images/plan/benefits-image.webp";

    return (
        <div className={mainContainerClass}>
            <h1>{textInfo.title}</h1>
            <div className={`${styles.imageBanner} flex-column`}>
                <LazyImage
                    srcWebp={imageWebp}
                    src={image}
                    alt=""
                />
            </div>
            <div className={styles.pointsBox} >
                { pageInfo.cards.map(card =>
                    (<LabelWithCheckbox
                        key={card.id}
                        theme={themeData.checkboxCardColor}
                        labelText={textInfo.labels[card.localID]}
                    />)
                )}
            </div>
            <ContinueButton
                theme={themeData.continueButton}
                text={strings.continue}
                nextStep={clickContinue}
            />
        </div>
    )
}   

export default Benefits;