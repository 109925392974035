import styles from "./CheckboxCard.module.scss";
import {useEffect, useState} from 'react';
import strings from "../../../assets/localization/strings.jsx";
import LazyImage from "../lazyImage/LazyImage";
import CustomCheckbox from "../checkbox/Checkbox";

const CheckboxCard = props => {
    const {
        data,
        selectCard,
        smallCard,
        withSubtitle,
        cardStyles,
        checkboxColor,
        isActive
    } = props;
    const [card, setCard] = useState(data);
    const textBox = strings[card.section][card.page].labels[card.localID]

    useEffect(() => {
        if (!isActive)
            return
        setCard({...card, checked: isActive !== -1 })
    }, [isActive])

    const clickCard = () => {
        setCard({...card, checked: !card.checked})
        selectCard({...card, checked: !card.checked});
    }

    const cardContainerActiveClass = `${styles.activeCard} ${styles[`${checkboxColor}ActiveCard`]}`
    const cardContainerClass = `${styles.cardContainer} ${card.checked ? cardContainerActiveClass : ''} ${smallCard ? styles.smallCardContainer : ''} flex-between`

    return (
        <div className={cardContainerClass}
             onClick={clickCard}
             style={cardStyles}
        >
            <div className='flex-row'>
                {!card.withoutImage && (
                    <LazyImage
                        className={card.small ? styles.cardIconSmall : styles.cardIcon}
                        srcWebp={card.iconWebp}
                        src={card.icon}
                        alt=""
                    />)
                }
                { withSubtitle ? (
                    <div className={`${card.withoutImage ? '' : styles.cardTitle} cardTitle ${styles.cardTitleWithSubtext}`}>
                        <p className={styles.cardText}>{textBox.text}</p>
                        <p className={styles.cardSubtext}>{textBox.subtext}</p>
                    </div>
                ) : (
                    <p className={`${card.withoutImage ? '' : styles.cardTitle} cardTitle`}>{textBox}</p>
                )}

            </div>
           { ((card.checkbox !== null && card.checkbox === true) || card.checkbox === null) && <CustomCheckbox
                data={{
                    "checked": card.checked,
                    "disabled": false
                }}
                color={checkboxColor}
                checkCard={()=>{}}
            /> }
        </div>
    )
}   

export default CheckboxCard;