import styles from "./UserReviewsBox.module.scss";
import BigReview from "./BigReview.jsx";
import strings from "../../../assets/localization/strings.jsx";
import LazyImage from "../lazyImage/LazyImage";

const UserReviewsBox = (props) => {
    const { themeData, data, reviews, bigReview } = props;
    console.log(reviews);
    const mainContainerClass = `${styles.mainContainer} ${styles[`${themeData.pageContainer}Container`]} flex-column`

    return (
        <div className={mainContainerClass}>

                <div className={`${styles.header} flex-column`}>
                    <div className={styles.title}>
                        {strings.intro.reviews.header}
                    </div>
                </div>

                <div className={`${styles.reviewGrade} flex-column`}>
                  <div className={styles.grade}>
                             <div className={styles.gradeValue}>4.6</div>
                             <div className={styles.basedOn}>{strings.intro.reviews.basedOn}</div>
                 </div>
                 <LazyImage
                                                 className={styles.reviewChart}
                                                 srcWebp="/images/intro/reviews_chart.webp"
                                                 src="/images/intro/reviews_chart.png"
                                                 alt=""
                     />
               </div>

               <div className={styles.reviews}>
               { reviews.map((review) => (<div key={review.id} className={styles.review}>
                                          <div className={styles.reviewHeader}>

                                             <div className={styles.reviewAvatar}>
                                                 <LazyImage
                                                          className={styles.avatar}
                                                          srcWebp={review.avatarWebp}
                                                          src={review.avatar}
                                                          alt=""
                                                 />
                                                 <LazyImage
                                                         className={styles.avatarIcon}
                                                         srcWebp="/images/intro/reviews_avatar_icon.webp"
                                                         src="/images/intro/reviews_avatar_icon.png"
                                                         alt=""
                                                 />
                                             </div>

                                              <div className={styles.reviewInfo}>

                                                  <div className={styles.reviewAuthor}>
                                                   <div className={styles.authorName}>{review.author} </div>
                                                   <div className={styles.authorVerified}>{strings.intro.reviews.verified}</div>
                                                  </div>

                                                   <div className={styles.reviewIcons}>
                                                   <LazyImage
                                                          className={styles.flag}
                                                          srcWebp={"/images/intro/reviews_flag_" + review.country_code + ".webp"}
                                                          src={"/images/intro/reviews_flag_" + review.country_code + ".png"}
                                                          alt=""
                                                       />
                                                      <div className={styles.country}>{review.country}</div>
                                                      <LazyImage
                                                                 className={styles.stars}
                                                                 srcWebp="/images/intro/reviews_stars.webp"
                                                                 src="/images/intro/reviews_stars.png"
                                                                 alt=""
                                                          />
                                                    </div>

                                              </div>

                                          </div>

                                          <div className={styles.reviewText}>{review.review}</div>
                               </div>
                     ))}

               </div>

               <BigReview review={bigReview} themeData={themeData}/>

            </div>



    )
}

export default UserReviewsBox;
