import { useEffect, useState } from "react";
import amplitude from 'amplitude-js';
import ContinueButton from "../../UI/continueButton/ContinueButton";
import Picker from "../../UI/picker/Picker";
import styles from "./Name.module.scss";
import AboutInput from "../../UI/aboutInput/AboutInput";

import strings from "../../../assets/localization/strings.jsx";

const Name = props => {
    const { nextStep, data } = props;
    const [name, setName] = useState('')
    const [buttonStyles, setButtonStyles] = useState({})
    const [errorMessage, setErrorMessage] = useState("")
    const [disableButton, setDisableButton] = useState(true);
    localStorage.removeItem('nameInfo');
    const pageInfo = data.settings.name
    const themeData = data.themeData
    const mainContainerClass = `${styles.mainContainer} ${styles[`${themeData.pageContainer}Container`]} flex-column`

    const changeInputName = data => {
        setErrorMessage(data.errorMessage);
        setDisableButton(data.disableButton);
        if(!data.disableButton)
            setName(data.value);
    }

    const inputOnFocus = () => {
        setTimeout(() => {
            setButtonStyles({ position: 'relative', marginTop: '45px', left: 'unset' })
        }, 100);
    };

    const inputOnBlur = () => {
        setTimeout(() => {
            setButtonStyles({})
        }, 200)
    };

    const clickContinue = () => {
        localStorage.setItem('nameInfo', name);
        let logText = 'button_onboarding_name_continue_tapped'
        amplitude.getInstance().logEvent(logText, {answer: name});
        const answer = new Map();
        answer.set("key", "name");
        answer.set("value", name);
        nextStep(answer);
    }

    useEffect(() => {
        setDisableButton(true);
        dataLayer.push({
                      "event": "ga4event",
                      "event_category": "screen_onboarding_name_appeared",
                      "funnel_type": data.campaign,
                      "step": pageInfo !== null && pageInfo !== undefined ? pageInfo.order : "0"
        });
        amplitude.getInstance().logEvent('screen_onboarding_name_appeared');
        return () => {
            setDisableButton(true);
        }
    }, [])

    return (
        <div className={mainContainerClass}>
            <h1 className={styles.title}>{strings.about.name.title}</h1>
                <>
                    <AboutInput
                        theme={themeData.inputColor}
                        checkResult={changeInputName}
                        onFocus={inputOnFocus}
                        onBlur={inputOnBlur}
                        validate={false}
                        width={'200px'}
                        inputProps={{ inputMode: 'text', placeholder: 'Enter your name'}}
                    />
                    { errorMessage && (<p className={styles.errorMessage}>{errorMessage}</p>) }
                    <ContinueButton
                        customStyles={buttonStyles}
                        nextStep={clickContinue}
                        disableButton={disableButton}
                        theme={themeData.continueButton}
                    />
                </>

        </div>
    )
}

export default Name;