import styles from "./ContinueButton.module.scss";
import strings from "../../../assets/localization/strings.jsx";

const ContinueButton = props => {
    const { text = strings.continue, theme, nextStep, disableButton, customStyles } = props;
    const buttonClassName = `${styles.buttonContainer} ${disableButton ? styles.disabledButton : ''}`
    const inlineStyles = {
        background: (disableButton ? theme?.backgroundDisabled : theme?.background),
        color: theme.color,
        ...customStyles
    }

    function styleExist(style, key){
    return style !== null && style !== undefined && (style[key] !== null &&  style[key] !== undefined);
    }

    return (
        <button
            ref={(node) => {
                if (node && theme?.boxShadow && !styleExist(customStyles, "box-shadow")) {
                    node.style.setProperty("box-shadow", theme.boxShadow, "important");
                }
            }}
            className={buttonClassName}
            style={inlineStyles}
            onClick={nextStep}
            disabled={!!disableButton}
        >
            {text}
            <img loading="lazy" src={styleExist(customStyles, "arrowSrc") ? customStyles.arrowSrc : theme?.arrowSrc} alt="" />
        </button>
    )
}   

export default ContinueButton;
