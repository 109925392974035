import LazyImage from "../../lazyImage/LazyImage";
import {Autoplay, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";

import styles from "./HeaderImage.module.scss";
import strings from "../../../../assets/localization/strings.jsx";
import VideoFeedbacks from "../../videoFeedbacks/VideoFeedbacks";

const ImageSlide = (props) => {
    const { settings } = props

    return (
        <div className={styles.swiperCard}>
            <LazyImage
                className={styles.logoImage}
                src={settings.src}
                srcWebp={settings.srcWebp}
                alt=""
            />
        </div>
    )
}

const HeaderImage = (props) => {
    const { data, theme, currentWeight, targetWeight } = props
    const mainContainerClass = `${styles.mainContainer} ${styles[`${theme.pageContainer}Container`]} `

    return (
        <Swiper
            className={mainContainerClass}
            spaceBetween={30}
            slidesPerView={"auto"}
            centeredSlides={true}
            autoplay={{
                delay: 5000,
                disableOnInteraction: false,
            }}
            pagination={{ clickable: true }}
            modules={[Autoplay, Pagination]}
        >
            { data.map((sliderData, idx) => (
                    <SwiperSlide key={idx} className={sliderData.type === 'chart' && styles.swiperSlide}>
                        { (<ImageSlide settings={sliderData} />)
                        }
                    </SwiperSlide>
                )
            )}
        </Swiper>
    )
}

export default HeaderImage;