import { useEffect, useState } from "react";
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';

import TextInput from "../../UI/textInput/TextInput";
import PasswordInput from "../../UI/passwordInput/PasswordInput";
import UserPool from "./UserPool";
import LazyImage from "../../UI/lazyImage/LazyImage";
import amplitude from 'amplitude-js';
import styles from "./RegistrationForm.module.scss";

import updatePaymentEvent from "../../../hooks/facebookCAPI";
import strings from "../../../assets/localization/strings.jsx";

const RegistrationForm = props => {
    const { nextStep, switchOption, setClosedPopup, setWaitingPopup, domain } = props
    const emailInfo = localStorage.getItem('emailInfo')
    const subscriptionId = localStorage.getItem('subscriptionId')
    const [disableButton, setDisableButton] = useState(true)
    const [inputFullName, setInputFullName] = useState('')
    const [nickname, setNickname] = useState('')
    const [inputEmail, setInputEmail] = useState(emailInfo)
    const [inputPassword, setInputPassword] = useState('')
    const [inputConfirmPassword, setInputConfirmPassword] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [infoMessage, setInfoMessage] = useState('')

    function getCookie(name) {
           const matches = document.cookie.match(new RegExp(
             "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
           ));
           return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    useEffect(() => {
        if(inputEmail)
            checkEmailPresence()

        setDisableButton(true)
        amplitude.getInstance().logEvent('screen_onboarding_create_account_appeared');
        return () => {
            setDisableButton(true)
        }
    }, [])

    const validateForm = (data, type) => {
        let fnV = inputFullName
        let eV = inputEmail
        let pV = inputPassword
        let cpV = inputConfirmPassword
        setErrorMessage('')
        setInfoMessage('')
        if(type === 'fullName'){
            setInputFullName(data)
            fnV = data
        }
        if(type === 'email'){
            setInputEmail(data.toLowerCase())
            eV = data.toLowerCase()
        }
        if(type === 'password'){
            setInputPassword(data)
            pV = data
        }
        if(type === 'confirmPassword'){
            setInputConfirmPassword(data)
            cpV = data
        }
        if(eV && !(/\S+@\S+\.\S+/.test(eV))) {
            setErrorMessage(strings.registration.emailErr)
            setDisableButton(true)
            return
        }
        if(pV && pV.length < 6) {
            setErrorMessage(strings.registration.passwordLength)
            setDisableButton(true)
            return
        }
        if(pV && cpV && pV !== cpV) {
            setErrorMessage(strings.registration.passwordMatch)
            setDisableButton(true)
            return
        }
        if(pV && cpV && eV && fnV) {
            setDisableButton(false)
        }
    }

    const clickBack =()=>{
    switchOption("all");
    }

    const clickContinue = () => {
        setDisableButton(true)
        setWaitingPopup(true)
        amplitude.getInstance().logEvent('button_onboarding_create_account_create_account_tapped');
        const attributeList = [];
        attributeList.push(new CognitoUserAttribute({ Name: 'email', Value: inputEmail.toLowerCase() }));
        UserPool.signUp(inputEmail.toLowerCase(), inputPassword, attributeList, null, (err, data) => {
            if (err) {
                setError()
            } else {
                signUp(data.userSub)
            }
        });
    }
    const setError = () => {
        amplitude.getInstance().logEvent('button_onboarding_create_account_account_created_failed')
        setWaitingPopup(false)
        setClosedPopup(false)
        setDisableButton(false)
    }

    const signUp = (cognitoUserId) => {
       // check inputFullName
         // send names to facebook capi event for higher event score
         try{
          const eventSystems = ['Subscribe', 'Purchase', 'StartTrial']
           if(inputFullName.includes(" ")){
                            // split to first name and last name
                            const names = inputFullName.split(' ');
                            const firstName = names[0];
                            const lastName = names[1];

                            eventSystems.map(action => {
                                         updatePaymentEvent(action, firstName, lastName, domain)
                            })

           } else{
                   eventSystems.map(action => {
                                    updatePaymentEvent(action, inputFullName, null, domain)
                  })
          }
         } catch(e){
         console.log(e);
         }


        let body = {
            "name": inputFullName,
            "email": inputEmail.toLowerCase(),
            "cognitoUserId": cognitoUserId,
            "authenticator": "cognito",
            "nickname": nickname,
            "platform": "web",
            "amplitude_user_id": amplitude.getInstance().options.userId,
            "subscription_id": subscriptionId,
            "onboarding_id": localStorage.getItem('onboarding_id')
        }
        if(localStorage.getItem("lifetime")){
        body.free_access = 1;
        }
        fetch("https://k1ltj4j168.execute-api.us-east-1.amazonaws.com/dev/create_user_profile", {
            method: "POST",
            body: JSON.stringify(body)
        }).then(async (result) => {

            let resultBody = result.json();
             await resultBody.then( async (json) => {

                   localStorage.setItem('profile_id', json.id);
            });
            if(result.status === 200){
                amplitude.getInstance().logEvent('button_onboarding_create_account_account_created')
                setWaitingPopup(false)
                setDisableButton(false)
                nextStep();
            } else {
                setError()
            }
        }).catch(async (e) => {
            setError()
        })
    }

    const checkEmailPresence = () => {
        if (!inputEmail || inputEmail && !(/\S+@\S+\.\S+/.test(inputEmail)))
            return
        fetch("https://k1ltj4j168.execute-api.us-east-1.amazonaws.com/dev/check_email_exists", {
            method: "POST",
            body: JSON.stringify({ "email": inputEmail}),
        }).then(async (response) => {
            let resultBody = response.json();
            await resultBody.then( async (json) => {
                if(json.email_exist) {
                    setErrorMessage(strings.registration.emailExists)
                } else {
                    setNickname(json.preferred_nickname)
                    setInfoMessage(strings.registration.emailOk)
                }
            });
        });
    }

    return (
        <div className={`${styles.mainContainer} flex-column`}>
        <div className={styles.formHeader}>
            <LazyImage className={styles.back}
                                    srcWebp="/images/icons/arrow_to_left.webp"
                                    src="/images/icons/arrow_to_left.png"
                                    alt=""
                                    onClick={clickBack}
                                />

            <h1 className={styles.title}>{strings.registration.title}</h1>
             </div>
            <p className={styles.subtitle}>{strings.registration.subtitle}</p>
            <div className={styles.formContainer}>
                <TextInput
                    label={strings.registration.fullname}
                    type={'fullName'}
                    checkResult={validateForm}
                    currentValue={inputFullName}
                />
                <TextInput
                    label={strings.registration.email}
                    type={'email'}
                    onBlur={checkEmailPresence}
                    checkResult={validateForm}
                    currentValue={inputEmail !== null && inputEmail !== 'null' ? inputEmail : ''}
                />
                <PasswordInput
                    label={strings.registration.password}
                    type={'password'}
                    checkResult={validateForm}
                    currentValue={inputPassword}
                />
                <PasswordInput
                    label={strings.registration.confirmPassword}
                    type={'confirmPassword'}
                    checkResult={validateForm}
                    currentValue={inputConfirmPassword
                }/>
                <div className={styles.errorContainer}>
                    { errorMessage && (<p className={`${styles.defaultMessage} ${styles.errorMessage}`}>{errorMessage}</p>) }
                    { infoMessage && (<p className={`${styles.defaultMessage} ${styles.infoMessage}`}>{infoMessage}</p>) }
                </div>
            </div>
            <button className={`${styles.buttonContainer} ${disableButton ? styles.disabledButton : ''}`}
                    onClick={clickContinue}
                    disabled={!!disableButton}
            >
                {strings.registration.create}
            </button>
            <div className={`${styles.agreements} flex-column`}>
                <span>{strings.registration.bySigning}</span>
                <div className={`${styles.agreementLinks} flex-row`}>
                    <a href={'https://everdance.app/terms'}>Terms&Service</a>
                    <span>. {strings.registration.view}</span>
                    <a href={'https://everdance.app/policy'}>Privacy Policy</a>
                    <span>.</span>
                </div>
            </div>
        </div>
    )
}

export default RegistrationForm;