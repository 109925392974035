import styles from "./MillionsUsersBox.module.scss";

import strings from "../../../../assets/localization/strings.jsx";
import LazyImage from "../../lazyImage/LazyImage";

const MillionsUsersBox = (props) => {
    const { themeData, type } = props;
    const mainContainerClass = `${styles.mainContainer} ${styles[`${themeData.pageContainer}Container`]} flex-column`

    return (
        <div className={mainContainerClass}>
            <div className={`${styles.headerBox} flex-row`}>
                <div className={styles.leftOliveImageBox} >
                    <LazyImage
                        className={styles.imageBox}
                        srcWebp="/images/paywall/greenTheme/leftOlive.webp"
                        src="/images/paywall/greenTheme/leftOlive.png"
                        alt=""
                    />
                </div>
                <div className={`${styles.header} flex-column`}>
                    <div className={styles.title}>
                        {type !== null && type !== undefined ? strings.paywall.greenTheme.downloadApps["title_" + type] : strings.paywall.greenTheme.downloadApps.title}
                    </div>
                    <div className={styles.subtitle}>
                        {type !== null && type !== undefined ? strings.paywall.greenTheme.downloadApps["subtitle_" + type] : strings.paywall.greenTheme.downloadApps.subtitleChair}
                    </div>
                    <div className={`${styles.downloadLinksBox} flex-row`}>
                                    <div className={styles.ratingImageBox}>
                                        <LazyImage
                                            className={styles.imageBox}
                                            srcWebp="/images/paywall/greenTheme/stars.webp"
                                            src="/images/paywall/greenTheme/stars.png"
                                            alt=""
                                        />
                                    </div>
                    </div>
                </div>
                <div className={styles.rightOliveImageBox}>
                    <LazyImage
                        className={styles.imageBox}
                        srcWebp="/images/paywall/greenTheme/rightOlive.webp"
                        src="/images/paywall/greenTheme/rightOlive.png"
                        alt=""
                    />
                </div>
            </div>


        </div>
    )
}

export default MillionsUsersBox;
