import { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';

import './AboutInput.scss';
import { styled } from "@mui/material/styles";
import styles from "./AboutInput.module.scss";
import strings from "../../../assets/localization/strings.jsx";

const CssTextField = styled(TextField)(({ settings }) => ({
    '.MuiInput-root': {
        height: '40px',
        width: settings.width,
        '& input': {
            caretColor: settings.caretColor,
            textAlign: 'center',
            fontSize: '26px',
            padding: '0',
            color: settings.color
        },
         '& input::placeholder': {
            fontSize: '16px',
          },
          '& input:focus::placeholder': {
             color: 'transparent',
          },
        '&:before': {
            borderBottom: settings.borderBottom
        }
    },
    '& label.Mui-focused': {
        color: '#A0AAB4',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#B2BAC2',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#E0E3E7',
        },
        '&:hover fieldset': {
            borderColor: '#B2BAC2',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#6F7E8C',
        },
    },
}));

const styledSettings = (theme, width) => {
    let settings = {
        caretColor: "#E25869",
        color: "#000000",
        width: width,
        borderBottom: "1px solid rgba(0, 0, 0, 0.42)"
    }

    switch(theme) {
        case "yellow":
            return { ...settings,
                caretColor: "#DAFF21",
                color: "#FFFFFF",
                borderBottom: "0.5px solid rgba(255, 255, 255, 0.3)"
            }
        default:
            return settings
    }
}

const AboutInput = props => {
    const { checkResult, inputType, min, max, currentValue, autoFocus, onFocus, onBlur, theme, inputProps, validate, width } = props;
    const [inputValue, setInputValue] = useState(currentValue);

    useEffect(() => {
        setInputValue(currentValue)
    }, [currentValue])

    const numericValidations = (value) => {
        if(!value)
            return { errorMessage: '', disableButton: true }
        if(value.match("^[0-9]*$") === null)
            return { errorMessage: strings.about.errors.numeric, disableButton: true }
        if(value && min && value < min) {
            return { errorMessage: strings.about.errors.errorGreater + `${min}`, disableButton: true }
        }
        if(value && max && value > max) {
            return { errorMessage:  strings.about.errors.errorLess + `${max}`, disableButton: true }
        }
        return { errorMessage: '', disableButton: false }
    }

    const changedInfo = (e) => {
        setInputValue(e.target.value)
        if(validate){
        checkResult({
                    value: e.target.value,
                    inputType,
                    ...numericValidations(e.target.value)
                });
        } else{
         checkResult({
                            value: e.target.value,
                            errorMessage: '',
                            disableButton: false
                        });
        }

    }
    const mainContainerClass = `${styles.mainContainer} ${styles[`${theme}Container`]} flex-row`

    return (
        <div className={mainContainerClass}>
            <CssTextField
                sx={{
                    '&:hover': { bgcolor: 'transparent' },
                }}
                onFocus={onFocus}
                onBlur={onBlur}
                autoFocus={autoFocus}
                defaultValue={currentValue}
                value={inputValue}
                variant="standard"
                inputProps={inputProps}
                color="warning"
                onChange={e => changedInfo(e)}
                settings={styledSettings(theme, width)}
            />
            {inputType && <span className={styles.inputType}>{inputType}</span>}
        </div>
    )
}   

export default AboutInput;