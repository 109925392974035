import {useEffect, useState} from "react";
import { useNavigate } from "react-router";

import ReactPixel from "react-facebook-pixel";
import TiktokPixel from 'tiktok-pixel';
import amplitude from 'amplitude-js';
import moment from "moment/moment";
import sha256 from "crypto-js/sha256";
import HeaderImage from "../../components/UI/paywall/headerImage/HeaderImage";
import ButtonCard from "../../components/UI/buttonCard/ButtonCard";
import GetButton from "../../components/UI/paywall/getProgramButton/GetButton";
import VideoFeedbacks from "../../components/UI/videoFeedbacks/VideoFeedbacks";
import useJsonParse from "../../hooks/useJsonParse";
import styles from "./Intro.module.scss";
import MillionsUsersBox from "../../components/UI/paywall/millionsUsersBox/MillionsUsersBox";
import BenefitsBox from "../../components/UI/paywall/benefitsBox/BenefitsBox";
import strings from "../../assets/localization/strings.jsx";
import {Box, CircularProgress, Typography} from "@mui/material";
import LazyImage from "../../components/UI/lazyImage/LazyImage";
import UserReviewsBox from "../../components/UI/userReviews/UserReviewsBox";
import AnyDevice from "../../components/UI/anyDevice/AnyDevice";
import BackgroundImageBox from "../../components/UI/backgroundImageBox/BackgroundImageBox";
import ContinueButton from "../../components/UI/continueButton/ContinueButton";
const token = 'EAArT7Rx5i30BAO9TAd7UXKoZAV2lxX2AE0ZC0o2H0hsH928qbp990KiB9HG1tpLNEkUSQ8ICx8UWvbHeeZCLIL5px8Y1la0TWZARRtLq6V0ERRq4tn25gcsHyZAFsuFeVA09uTXdANgoi70gk2h0oIK9zONzQFxPFLGjuGOh4pVAvIciJsmU3mag4gRfBfN0ZD';
const netToken = 'EAACZBgaOc86EBOZBtOPqwuocghfnngNyTZA20iQcbMGWXTM8lPutMXk6gsIw3csDiZAqSNPCHejHLSHg3TJkea6ZCVIwJJaRcEcV8vXKxk6TYGuDiWBfFaMWAfokNL1mrhzoE01qhaTE2GH42aDrdMKpdwnoCsFz6Mvd9NB0neyF9Oz8HxVIux47RYtW7OWvdWAZDZD';
const tiktokApiToken = 'e9cb49ddaa71504c0628507456187bbfb9368637';

const IntroLanding = props => {
    const { data } = props;
    const pageInfo = data.settings.intro;
    const reviews = pageInfo.reviews;
    const themeInfo = data.themeData;

    const [textButton, setTextButton] = useState(strings.continue);

    const navigate = useNavigate();

    useEffect(() => {


        amplitude.getInstance().logEvent('screen_onboarding_intro_appeared');
        //localStorage.removeItem('discountStatus');
        const eventId = `viewcontent.${getCookie('id')}`;
        ReactPixel.track('ViewContent', {eventID: eventId});
        TiktokPixel.track('ViewContent', {event_id: eventId});
        fetch(`https://graph.facebook.com/v10.0/${(data.domain.includes('net') ? '1085241235883188' : '239948538703054')}/events?access_token=${data.domain.includes('net')? netToken: token}`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                data: [
                    {
                        event_name: "ViewContent",
                        event_time: moment().unix(),
                        action_source: "website",
                        event_id: eventId,
                        user_data: {
                            client_user_agent: navigator.userAgent,
                            external_id: sha256(getCookie('id')).toString()
                        }
                    }
                ]
            })
        })
        const payload = {


                                                             "pixel_code": "CP2RST3C77UF83EV1CUG",
                                                             "event_id": eventId,
                                                             "event": "ViewContent",
                                                             "timestamp": new Date(Date.now()),
                                                             "context": {
                                                              user: {
                                                                    external_id: sha256(getCookie('id')).toString()
                                                                 },
                                                              user_agent: navigator.userAgent,
                                                             },
                                                           };

                                                           const ttc = document.cookie.split(';').filter(c => c.includes('tiktok_click_id=')).map(c => c.split('tiktok_click_id=')[1]).join();
                                                           const ttp = document.cookie.split(';').filter(c => c.includes('_ttp=')).map(c => c.split('_ttp=')[1]).join();

                                                           if(ttc) {
                                                             payload.context.user.ttclid = ttc;
                                                           }

                                                           if(ttp) {
                                                             payload.context.user.ttp = ttp;
                                                           }

                                                           try{

                                                           fetch('https://smvcqm8fu5.execute-api.us-east-1.amazonaws.com/dev/tiktok_pixel_event', {
                                                                   method: "POST",
                                                                   headers: {
                                                                        'Accept': 'application/json',
                                                                        'Content-Type': 'application/json'
                                                                   },
                                                                   body: JSON.stringify(
                                                                         payload
                                                                    ),
                                                                  });

                                                           } catch(e){
                                                           console.log("tiktok track event error" + e)
                                                           }


    }, [])

    function getCookie(name) {
        const matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

     const nextPage = () => {
            navigate('/paywall')
        }


    return (

     <div className={`${styles.landing} ${styles[`${themeInfo.pageContainer}PageContainer`]}`}>

         <div className={styles.imageBox}>
                         <img loading="lazy" className={styles.logoImage} src="/images/logo-black.svg" alt="" />
                     </div>

         <h1 className={`${styles.title} ${pageInfo.cfTitle ? styles.landingTitle : ''}`}>
                       {strings.intro.header}
         </h1>
         <div className={styles.landingSubTitle}>
                   {strings.intro.subheader}
         </div>

         <MillionsUsersBox themeData={themeInfo} />

         <div className={styles.headerImage}>
                 <HeaderImage
                       data={pageInfo.headerImages}
                       theme={themeInfo}
                 />
         </div>
         <BenefitsBox themeData={themeInfo} data={data} headerSize={"big"}/>

          <GetButton
                                 text={textButton}
                                 nextPage={() => nextPage()}
                                 style={pageInfo.style}
                                 themeData={themeInfo}
                             />
            {pageInfo.feedbacks && (<VideoFeedbacks
                                                      themeData={themeInfo}
                                                      feedbacks={pageInfo.feedbacks}
                                                      title={true}
                                                      name={true}
                                                      view="circle"
            />)}
            <UserReviewsBox themeData={themeInfo} reviews={pageInfo.reviews} bigReview={pageInfo.bigReview}/>

            <AnyDevice themeData={themeInfo}/>


           <div className={styles.asFeatured}>
            <h2 className={`${styles.titleFeatured}`}>{strings.paywall.featured}</h2>
                                  <LazyImage
                                                         className={styles.featuresImage}
                                                         src="/images/paywall/features-image.png"
                                                         srcWebp="/images/paywall/features-image.webp"
                                                         alt=""
                                 />
            </div>

            <GetButton
                                             text={textButton}
                                             nextPage={() => nextPage()}
                                             style={pageInfo.style}
                                             themeData={themeInfo}
                                         />

        </div>

    )
}   

export default IntroLanding;