import { useEffect, useState } from 'react';
import RegistrationStepper from "../../components/UI/registrationStepper/RegistrationStepper";
import RegistrationForm from "../../components/createAccount/registrationForm/RegistrationForm";
import RegistrationFormSelect from "../../components/createAccount/registrationForm/RegistrationFormSelect";
import stepsData from "../../core/static/createAccount/registrationSteps.json";
import styles from "./CreateAccount.module.scss";
import RegistrationResult from "../../components/createAccount/registrationResult/RegistrationResult";
import FailedRegistrationPopup from "../../components/UI/failedRegistrationPopup/FailedRegistrationPopup";
import WaitingRegistrationPopup from "../../components/UI/waitingRegistrationPopup/WaitingRegistrationPopup";
import RegistrationOfferList from "../../components/createAccount/registrationOfferList/RegistrationOfferList";
import amplitude from "amplitude-js";
import strings from "../../assets/localization/strings";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { FacebookProvider } from 'react-facebook';
import LiveClasses from "../../components/createAccount/liveClasses/LiveClasses";

const CreateAccount = () => {
    const [activeStep, setActiveStep] = useState(1);
    const [stepName, setStepName] = useState('RegistrationForm');
    const [stepComponent, setStepComponent] = useState();
    const [registrationOption, setRegistrationOption] = useState("all");
    const [closedFailedRegistrationPopup, setClosedFailedRegistrationPopup] = useState(true)
    const [displayWaitingRegistrationPopup, setDisplayWaitingRegistrationPopup] = useState(false)

    const domain = localStorage.getItem('domain')
    // const withLiveClasses = localStorage.getItem('liveClasses')
    const withLiveClasses = false
    const steps = withLiveClasses ?
        stepsData.filter((el) => el.component !== "Empty" ) :
        stepsData.filter((el) => el.component !== "LiveClasses" )

    useEffect(() => {
        setClosedFailedRegistrationPopup(true)
        setDisplayWaitingRegistrationPopup(false)
    }, [])

    const tryAgainAction = () => {
        setClosedFailedRegistrationPopup(true);
    }

    const nextStep = () => {
        setActiveStep((prev) => prev + 1);
    };

    const registerOption = (option) => {
                setRegistrationOption(option);
   };

    const skipStep = () => {
        amplitude.getInstance().logEvent('button_onboarding_skip_tapped');
        nextStep();
    }

    useEffect(() => {

        const name = steps[activeStep-1].component

            if(name === "Empty") {
                nextStep()
                return
            }
        setStepName(name)
        setStepComponent(findStep(name, registrationOption))
    }, [activeStep, registrationOption])

    const findStep = (value, registrationOption) => {
        switch(value) {
            case "RegistrationForm":
                return <GoogleOAuthProvider clientId='1023626244689-jos22lkfke1tvm6qb959tpm3eqrb39a1.apps.googleusercontent.com'>
                                         {registrationOption === "all"
                                         ?  <FacebookProvider appId="499580667556769"><RegistrationFormSelect nextStep={nextStep}  switchOption={registerOption}  setClosedPopup={setClosedFailedRegistrationPopup} setWaitingPopup={setDisplayWaitingRegistrationPopup} domain={domain} /> </FacebookProvider>
                                         : <RegistrationForm nextStep={nextStep}  switchOption={registerOption}  setClosedPopup={setClosedFailedRegistrationPopup} setWaitingPopup={setDisplayWaitingRegistrationPopup} domain={domain} /> }
                                        </GoogleOAuthProvider>;
            case "LiveClasses":

                             const classes = JSON.parse(localStorage.getItem('liveClasses'));

                             let classesList = [];
                             for(let l = 0; l < classes.classes.length; l++){
                                classesList.push(classes.classes[l]);
                             }

                            return <LiveClasses nextStep={nextStep} lessonCards={classesList}/>;
            case "RegistrationOfferList":
                return <RegistrationOfferList nextStep={nextStep} mealPlan={true} />;
            case "RegistrationResult":
                return <RegistrationResult />;
            default:
                return <GoogleOAuthProvider clientId='1023626244689-jos22lkfke1tvm6qb959tpm3eqrb39a1.apps.googleusercontent.com'>
                                                                       {registrationOption === "all"
                                                                       ? <FacebookProvider appId="499580667556769"><RegistrationFormSelect nextStep={nextStep}  switchOption={registerOption}  setClosedPopup={setClosedFailedRegistrationPopup} setWaitingPopup={setDisplayWaitingRegistrationPopup} domain={domain} /></FacebookProvider>
                                                                       : <RegistrationForm nextStep={nextStep}  switchOption={registerOption}  setClosedPopup={setClosedFailedRegistrationPopup} setWaitingPopup={setDisplayWaitingRegistrationPopup} domain={domain} /> }
                                                                      </GoogleOAuthProvider>;
        }
    }
    const stepsWithSkip = ["LiveClasses", "RegistrationOfferList"]

    return (
        <div className={`${styles.mainContainer} flex-column container`}>
            { !closedFailedRegistrationPopup ? (<FailedRegistrationPopup onSubmit={tryAgainAction} />) : null }
            { displayWaitingRegistrationPopup ? (<WaitingRegistrationPopup />) : null }
            <div className={styles.imageContainer}>
                <img className={styles.logoImage} loading="lazy" src="/images/logo-black.svg" alt="" />
                {stepsWithSkip.indexOf(stepName) > -1 && (
                    <div className={`${styles.skipButton} flex-row`} onClick={skipStep}>
                        <span className={styles.buttonText}>{strings.registration.skipLink}</span>
                        <img className={styles.skipIcon} loading="lazy" src="/images/icons/gray-arrow-small.svg" alt="" />
                    </div>
                )}
            </div>
            <RegistrationStepper steps={steps} currentStep={activeStep} />
            {stepComponent}
        </div>
    )
}

export default CreateAccount;