import { useState, useEffect } from 'react';
import useJsonParse from "./useJsonParse";
import sha256 from 'crypto-js/sha256';
import amplitude from 'amplitude-js';
const token = 'EAArT7Rx5i30BAO9TAd7UXKoZAV2lxX2AE0ZC0o2H0hsH928qbp990KiB9HG1tpLNEkUSQ8ICx8UWvbHeeZCLIL5px8Y1la0TWZARRtLq6V0ERRq4tn25gcsHyZAFsuFeVA09uTXdANgoi70gk2h0oIK9zONzQFxPFLGjuGOh4pVAvIciJsmU3mag4gRfBfN0ZD';
const netToken = 'EAACZBgaOc86EBOZBtOPqwuocghfnngNyTZA20iQcbMGWXTM8lPutMXk6gsIw3csDiZAqSNPCHejHLSHg3TJkea6ZCVIwJJaRcEcV8vXKxk6TYGuDiWBfFaMWAfokNL1mrhzoE01qhaTE2GH42aDrdMKpdwnoCsFz6Mvd9NB0neyF9Oz8HxVIux47RYtW7OWvdWAZDZD';

function updatePaymentEvent (name, firstName, lastName, domain, fbID)  {

        try{
         amplitude.getInstance().logEvent('update_purchase_event_with_names', {names: firstName + " " + lastName});
        }catch(e){
         amplitude.getInstance().logEvent('update_purchase_event_with_names_error', {err: e});
        }



        let payloadData = null;
        if(name === "Purchase" || name === "Subscribe" || name === "StartTrial"){
        payloadData = localStorage.getItem('fcapi_purchase_payload')
        }

        if(payloadData !== null){


        let payload = JSON.parse(payloadData)

        if(firstName !== null) {
           payload.data[0].user_data.fn = [ sha256(firstName).toString()]
        }

        if(lastName !== null) {
           payload.data[0].user_data.ln = [ sha256(lastName).toString()]
        }

        if(fbID !== null && fbID !== undefined){

         payload.data[0].user_data.fb_login_id = fbID
        }

        fetch('https://graph.facebook.com/v10.0/' + (domain.includes('net') ? '1085241235883188' : '239948538703054') + '/events?access_token=' + (domain.includes('net')? netToken: token), {
                    method: "POST",
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
        })

        // now we can remove this event payload from local storage
        try{
           amplitude.getInstance().logEvent('update_purchase_event_with_names_sent', {names: firstName + " " + lastName});
        }catch(e){
           amplitude.getInstance().logEvent('update_purchase_event_with_names_sent_error', {err: e});
        }
        localStorage.removeItem('fcapi_purchase_payload');

        }

    }

export default updatePaymentEvent

