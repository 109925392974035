import CoverflowSwiper from "../../coverflowSwiper/CoverflowSwiper";
import styles from "./stayOnTopBox.module.scss";
import strings from "../../../../assets/localization/strings";

const stayOnTopBox = (props) => {
    const { data, themeData } = props;
    const mainContainerClass = `${styles.mainContainer} ${styles[`${themeData.pageContainer}Container`]} flex-column`
    let titleKey = data.settings.paywall.stayOnTopBoxTitle;
    if(titleKey === null || titleKey === undefined){
    titleKey = "titleOne";
    }

    return (
        <div className={mainContainerClass} >
            <h2 className={styles.title}>
                {strings.paywall.greenTheme.stayOnTop[titleKey]}
                <span>{strings.paywall.greenTheme.stayOnTop.titleTwo}</span>
            </h2>
            <div className={styles.swiperArea}>
                <CoverflowSwiper theme={themeData.pageContainer} data={data} />
            </div>

        </div>
    )
}

export default stayOnTopBox
