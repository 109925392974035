import styles from "./AnyDevice.module.scss";
import strings from "../../../assets/localization/strings.jsx";
import LazyImage from "../lazyImage/LazyImage";
import LabelWithCheckbox from "../labelWithCheckbox/LabelWithCheckbox";

const AnyDevice = (props) => {
    const { themeData, data } = props;

    const mainContainerClass = `${styles.mainContainer} ${styles[`${themeData.pageContainer}Container`]} flex-column`

    return (
        <div className={mainContainerClass}>
                     <div className={styles.anyDeviceHeader}>{strings.payment.proove.anyDevice}</div>
                      <div className={styles.anyDeviceImage}>
                                                                   <LazyImage
                                                                       srcWebp="/images/payment/any_device.webp"
                                                                       src="/images/result/any_device.png"
                                                                       alt=""
                                                                   />

                       </div>
                       <div className={styles.points} >
                                   <LabelWithCheckbox
                                     key={0}
                                     size={"xl"}
                                     theme={themeData.checkboxCardColor}
                                     labelText={strings.intro.anyDevice.lowImpact}
                                   />
                                   <LabelWithCheckbox
                                                 key={1}
                                                 size={"xl"}
                                                 theme={themeData.checkboxCardColor}
                                                 labelText={strings.intro.anyDevice.lowImpact}
                                   />
                                   <LabelWithCheckbox
                                                             key={2}
                                                             size={"xl"}
                                                             theme={themeData.checkboxCardColor}
                                                             labelText={strings.intro.anyDevice.noFloor}
                                   />
                                   <LabelWithCheckbox
                                                                         key={3}
                                                                         size={"xl"}
                                                                         theme={themeData.checkboxCardColor}
                                                                         labelText={strings.intro.anyDevice.home}
                                   />
                                    <LabelWithCheckbox
                                                                                                            key={4}
                                                                                                            size={"xl"}
                                                                                                            theme={themeData.checkboxCardColor}
                                                                                                            labelText={strings.intro.anyDevice.program}
                                                                      />
                                   </div>
        </div>
    )
}

export default AnyDevice;
