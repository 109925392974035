import styles from "./BonusesBox.module.scss";
import LabelWithCheckbox from "../../labelWithCheckbox/LabelWithCheckbox";
import strings from "../../../../assets/localization/strings.jsx";
import LazyImage from "../../lazyImage/LazyImage";

const BonusesBox = (props) => {
    const { themeData, data, headerSize } = props;
    const pageInfo = data.settings.benefits;
    const textInfo = strings.paywall.greenTheme.benefits;
    const mainContainerClass = `${styles.mainContainer} ${styles[`${themeData.pageContainer}Container`]} flex-column`

    return (
        <div className={mainContainerClass}>
               <div className={styles.header}>
                                                                            <span className={styles.text}>{strings.payment.proove.bonuses.getBonuses.get}</span>
                                                                            <span className={styles.redText}>{strings.payment.proove.bonuses.getBonuses.freeBonuses}</span>
                                                                            <span className={styles.text}>{strings.payment.proove.bonuses.getBonuses.beforeExpire}</span>
                                          </div>
               <div className={styles.bonuses}>

                           <div className={styles.bonusString}>
                                      <span className={styles.bonus}>{strings.payment.proove.bonuses.mealPlan}</span>
                                      <span className={styles.value}> (Free Bonus - $99 {strings.payment.proove.bonuses.value})</span>
                           </div>
                           <div className={styles.bonusString}>
                                     <span className={styles.bonus}>{strings.payment.proove.bonuses.feedback}</span>
                                     <span className={styles.value}> (Free Bonus - $99 {strings.payment.proove.bonuses.value})</span>
                           </div>
                           <div className={styles.bonusString}>
                                    <span className={styles.bonus}>{strings.payment.proove.bonuses.workouts}</span>
                                    <span className={styles.value}> (Free Bonus - $199 {strings.payment.proove.bonuses.value})</span>
                           </div>
                           <div className={styles.bonusString}>
                                    <span className={styles.bonus}>{strings.payment.proove.bonuses.tracker}</span>
                                    <span className={styles.value}> (Free Bonus - $99 {strings.payment.proove.bonuses.value})</span>
                           </div>
                           <div className={styles.bonusString}>
                                    <span className={styles.bonus}>{strings.payment.proove.bonuses.vip}</span>
                                    <span className={styles.value}> (Free Bonus - $199 {strings.payment.proove.bonuses.value})</span>
                           </div>



                          </div>

                <div className={styles.boughtSeparately}>
                                  <span className={styles.bought}>{strings.payment.proove.bonuses.boughtSeparately}</span>
                                   <span className={styles.price}> $695</span>
                 </div>

                <div className={styles.getFree}>
                        {strings.payment.proove.bonuses.getFree}
                 </div>

            </div>



    )
}

export default BonusesBox;
