import styles from "./UserReviewsBox.module.scss";
import strings from "../../../assets/localization/strings.jsx";
import LazyImage from "../lazyImage/LazyImage";

const BigReview = (props) => {
    const { themeData, data, review } = props;

    const mainContainerClass = `${styles.mainContainer}`

    return (
        <div className={mainContainerClass}>

                <div className={`${styles.header} flex-column`}>
                    <div className={styles.bigTitle}>
                        {review.header}
                    </div>
                </div>
                <LazyImage
                                                                 className={styles.bigReviewImage}
                                                                 srcWebp={review.imageWebp}
                                                                 src={review.image}
                                                                 alt=""
                />

                <div className={`${styles.bigReviewText}`}>

                 {review.text}

               </div>
            </div>



    )
}

export default BigReview;
