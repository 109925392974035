import styles from "./BenefitsBox.module.scss";
import LabelWithCheckbox from "../../labelWithCheckbox/LabelWithCheckbox";
import strings from "../../../../assets/localization/strings.jsx";
import LazyImage from "../../lazyImage/LazyImage";

const BenefitsBox = (props) => {
    const { themeData, data, headerSize } = props;
    const pageInfo = data.settings.benefits;
    const textInfo = strings.paywall.greenTheme.benefits;
    const mainContainerClass = `${styles.mainContainer} ${styles[`${themeData.pageContainer}Container`]} flex-column`

    return (
        <div className={mainContainerClass}>

                <div className={`${headerSize === "big" ? styles.headerBig : styles.header} flex-column`}>
                    <div className={styles.title}>
                        {strings.paywall.greenTheme.benefits.title}
                    </div>
                </div>
                <div className={styles.pointsBox} >
                                {( pageInfo.cardsL && pageInfo.display_cards === "2_column") && <div className={styles.leftPoints} >
                                  { pageInfo.cardsL.map(card =>
                                       (<LabelWithCheckbox
                                               key={card.id}
                                               size={"s"}
                                               theme={themeData.checkboxCardColor}
                                               labelText={textInfo.labels[card.localID]}
                                       />)) }
                                </div>}
                              {( pageInfo.cardsR && pageInfo.display_cards === "2_column")&& <div className={styles.rightPoints}>
                                  { pageInfo.cardsR.map(card =>
                                           (<LabelWithCheckbox
                                               key={card.id}
                                               size={"s"}
                                               theme={themeData.checkboxCardColor}
                                               labelText={textInfo.labels[card.localID]}/>)
                                        )
                                }
                                </div>}
                                { (pageInfo.cards && pageInfo.display_cards === "1_column") && <div className={styles.rightPoints}>
                                                                  { pageInfo.cards.map(card =>
                                                                           (<LabelWithCheckbox
                                                                               key={card.id}
                                                                               size={"l"}
                                                                               theme={themeData.checkboxCardColor}
                                                                               labelText={textInfo.labels[card.localID]}/>)
                                                                        )
                                                                }
                                </div>}
               </div>

            </div>



    )
}

export default BenefitsBox;
