import styles from "./UserLoveBox.module.scss";

import strings from "../../../../assets/localization/strings.jsx";
import LazyImage from "../../lazyImage/LazyImage";

const UserLoveBox = (props) => {
    const { themeData, data } = props;
    const textInfo = strings.plan.usersLove;
    const mainContainerClass = `${styles.mainContainer} ${styles[`${themeData.pageContainer}Container`]} flex-column`

    return (
        <div className={mainContainerClass}>


                <div className={`${styles.header} flex-column`}>
                    <div className={styles.title}>
                        {textInfo.title}
                    </div>
                </div>
                <div className={styles.imagesArea} >

                       <LazyImage
                            srcWebp={`/images/plan/usersLove/main.webp`}
                            src={`/images/plan/usersLove/main.png`}
                            alt=""
                         />

               </div>

        </div>
    )
}

export default UserLoveBox;
