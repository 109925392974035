import styles from "./WhatYouGetBox.module.scss";

import strings from "../../../../assets/localization/strings.jsx";
import LazyImage from "../../lazyImage/LazyImage";
import LabelWithCheckbox from "../../labelWithCheckbox/LabelWithCheckbox";
const WhatYouGetBox = (props) => {
    const { themeData, data } = props;
    const pageInfo = data.settings.whatYouGet;
    const textInfo = strings.paywall.greenTheme.whatYouGet;
    const mainContainerClass = `${styles.mainContainer} ${styles[`${themeData.pageContainer}Container`]} flex-column`

    return (
        <div className={mainContainerClass}>


                <div className={`${styles.header} flex-column`}>
                    <div className={styles.title}>
                        {textInfo.title}
                    </div>
                </div>
                <div className={styles.pointsBox} >
                                { pageInfo.cards.map(card =>
                                    (<LabelWithCheckbox
                                        key={card.id}
                                        theme={themeData.checkboxCardColor}
                                        labelText={textInfo.labels[card.localID]}
                                    />)
                                )}
               </div>

            </div>



    )
}

export default WhatYouGetBox;
