// FacebookPixel.js

import React, { useEffect } from "react";

const Facebook = (props) => {
const { domain } = props;
  useEffect(() => {
    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = "2.0";
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(
      window,
      document,
      "script",
      "https://connect.facebook.net/en_US/fbevents.js"
    );

    console.log(domain);
    fbq('dataProcessingOptions', ['LDU'], 0, 0);
    fbq("init", (domain.includes('net') ? '1085241235883188' : '239948538703054')); // Insert your Pixel ID here
    fbq("track", "PageView");
  }, []);

  return null;
};

export default Facebook;
